/* 
bebas-neue
garamond-premier-pro
proxima-nova
proxima-nova-condensed 
*/

.headline {
  font-size: 60pt;
  font-family: bebas-neue;
  color: #1C3664;
}

.title {
  /* font-size: 42pt; */
  font-family: bebas-neue;
  color: #1C3664;
}

.title-secondary {
  /* font-size: 42pt; */
  font-family: bebas-neue;
  color: #448EA7;
}

.subhead {
  font-size: 28pt;
  font-family: bebas-neue;
  color: #1C3664;
}

.quote {
  font-family: garamond-premier-pro;
  color: #1C3664;
}

.navigation-bar-text {
  font-size: medium;
  font-family: proxima-nova;
  color: #1C3664;
}

.navigation-bar-select {
  font-size: medium;
  font-family: proxima-nova;
  color: #99CCCC;
  font-weight: bold;
}

.about-body {
  font-family: proxima-nova;
  color: #1C3664;
}

.active-tab {
  background-color: #99CCCC;
  font-family: proxima-nova;
}

.footer {
  background-color: #99CCCC;
}

.inactive-tab-text {
  font-family: proxima-nova;
}

.active-tab-text {
  color: #1C3664;
  font-weight: bold;
  font-family: proxima-nova;
}

.blue-bg {
  background-color: #1C3664;
}

.green-bg {
  background-color: #99CCCC;
}

.grey-bg {
  background-color: #DCDDE2;
}

.light-grey-bg {
  background-color: #F4F5F9;
}

.cardHeaderBlue {
  background-color: #1C3664;
  font-family: bebas-neue;
  color: white;
}

.cardHeaderGreen {
  background-color: #99CCCC;
  font-family: bebas-neue;
  color: #1C3664;
}

.about-bg {
  background-image: url('./assets/img/about_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.round-img {
  border-radius: 50%;
}

.round-btn-icon {
  border-radius: 50%;
  height: 3em;
  width: 3em;
}

.homeCardBody {
  height: 20rem;
}

.exercise-card-bg {
  background-color: #FDFDFA;
}

.next-btn {
  cursor: pointer;
}

.options-header {
  font-family: bebas-neue;
}